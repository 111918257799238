@import 'variables.css';

/* For Loader */
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* For Loader */

.cursor-pointer {
  cursor: pointer !important;
  user-select: none;
}

.cursor-pointer-h {
  cursor: pointer !important;
  transition: all .3s ease-in-out;
  user-select: none;
}

.cursor-pointer-h:hover {
  transform: scale(1.15);
}

.custom-success-btn {
  background-color: rgba(144, 238, 144, 0.2) !important;
  color: rgb(2, 78, 2) !important;
  font-weight: 500 !important;
  min-width: 150px;
}

.custom-danger-btn {
  background-color: rgba(218, 115, 115, 0.418) !important;
  color: red !important;
  font-weight: 500 !important;
  min-width: 150px;
}

.custom-warnings-btn {
  background-color: rgba(255, 187, 0, 0.288) !important;
  color: rgb(109, 80, 2) !important;
  font-weight: 500 !important;
  min-width: 150px;
}

.profile-box {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-box-sm {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-color-1 {
  background-color: var(--color-1);
}

.bg-color-2 {
  background-color: var(--color-2);
}

.bg-color-3 {
  background: var(--color-3);
}

.bg-color-4 {
  background-color: var(--color-4);
}

.bg-color-5 {
  background-color: var(--color-5);
}

.bg-color-6 {
  background-color: var(--color-6);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}


/* Chat */

.outgoing {
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.incoming {
  display: block;
  width: 100%;

}

.outgoing .text {
  border-radius: 15px 0px 15px 15px;
  margin: 5px;
  padding: 8px;
  color: black;
  width: fit-content;
  max-width: 60%;
  margin-right: 15px;
}

.textarea {
  resize: none;
}

.incoming .text {
  border-radius: 0px 15px 15px 15px;
  margin: 5px;
  padding: 10px;
  color: black;
  width: fit-content;
  max-width: 60%;
  margin-left: 15px;
}

@keyframes incomingAnimation {
  0% {
    opacity: 0;
    transform: translate(-70%, 0px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes outgoingAnimation {
  0% {
    opacity: 0;
    transform: translate(70%, 0px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

.chat-name {
  font-size: 14px;
  color: gray;
  height: 16px;
}


.custom-chat-input {
  border: none;
  outline: none;
}

.chat-box {
  overflow: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

.send-message-box {
  position: absolute;
  bottom: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}


/* Tabs */
.tabs .active {
  background-color: rgba(0, 123, 255, 0.9) !important;
  color: white !important;
  font-weight: 500;
}

.imageModal {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}